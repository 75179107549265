<template>
  <div class="auth-wrapper auth-v2 h-100">
    <b-row class="auth-inner m-0">
      <!-- Login-->
      <b-col class="d-flex flex-column mb-3 auth-login">
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 text-center auth-title"
            title-tag="h1"
          >
            Transfersmile Wallet Admin
          </b-card-title>
          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- email -->
              <b-form-group
                label="Username"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="Please enter your email address..."
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Please enter your password..."
                      autocomplete="current-password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Google Authentication Code -->
              <b-form-group
                label="Google Authentication Code"
                label-for="authentication-code"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Google Authentication Code"
                  :rules="isAuthCode ? '' : 'required'"
                >
                  <b-form-input
                    id="authentication-code"
                    v-model="authenticationCode"
                    :state="errors.length > 0 ? false:null"
                    name="authentication-code"
                    placeholder="Please enter the verification code..."
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group class="text-right">
                <b-button
                  variant="flat-primary"
                  size="sm"
                  @click="register"
                >
                  Register
                </b-button>
                <!-- <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  Remember Me
                </b-form-checkbox> -->
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                Sign in
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
    <!-- Register for Google Authenticator -->
    <b-modal
      ref="googleAuth"
      ok-only
      ok-title="Okey"
      centered
      title="Register for Google Authenticator"
      hide-footer
    >
      <b-card-text class="login-modal-p">
        1. Download the 'Google Authenticator' APP
      </b-card-text>
      <b-card-text class="login-modal-p">
        2. Scan the QR code below
      </b-card-text>
      <div class="login-qrcode">
        <img
          :src="qrCodeImageUrl"
          alt="qr code"
        >
      </div>
      <b-button
        class="mt-2 mb-2 login-modal-okey"
        variant="primary"
        block
        @click="hideModal"
      >
        Okey
      </b-button>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
  BToast,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { updateNavMenuItems, getDefaultName } from '@/libs/utils'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      // password: "admin",
      // userEmail: "admin@demo.com",
      password: '', // 123456
      userEmail: '', // admin
      authenticationCode: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),

      isAuthCode: false,
      // validation rules
      required,
      email,
      qrCodeImageUrl: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    if (window.location.href.includes('8080')) {
      this.userEmail = '1'
      this.authenticationCode = '2'
      this.password = '3'
    }
    localStorage.removeItem('navMenuItems')
  },
  methods: {
    register() {
      this.isAuthCode = true
      this.$nextTick(() => {
        this.$refs.loginForm.validate().then(async success => {
          if (success) {
            const params = {
              platform: 3,
              user_name: this.userEmail,
              password: this.password,
            }
            this.$showLoading()
            try {
              const res = await this.$api.getQrcode(params).catch(async () => {
                await this.$api.getQrcodeError(params).catch(error => {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Notification',
                      icon: 'BellIcon',
                      text: `${error.message}`,
                      variant: 'danger',
                    },
                  }, {
                    position: 'top-center',
                  })
                })
              })
              this.$hideLoading()
              const blob = await this.getBase64(res.data)
              this.qrCodeImageUrl = blob
              this.$refs.googleAuth.show()
            } catch (error) {
              this.$hideLoading()
            }
          }
        })
      })
    },
    getBase64(data) {
      return new Promise((resolve, reject) => {
        const blob = new Blob([data], { type: 'image/png' })
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    login() {
      if (window.location.href.includes('8080')) {
        const token = '1234567890'
        const data = { token }
        localStorage.setItem('accessToken', token)
        localStorage.setItem('refreshToken', token)
        localStorage.setItem('userData', JSON.stringify(data))
        const navMenuItems = [{
          created_at_ms: '0',
          description: 'Merchant Center',
          feature_id: 'merchant-center',
          feature_list: [],
          feature_name: 'Merchant Center',
          icon: 'UsersIcon',
          parent_feature_id: '',
          perm: 1,
          route: '',
          title: 'Merchant Center',
          children: [{
            created_at_ms: '0', description: 'List', feature_id: 'merchant_center_list', feature_list: [], feature_name: 'List', icon: '', parent_feature_id: 'merchant_center', perm: 1, route: 'Merchant Center List', title: 'List',
          }, {
            created_at_ms: '0', description: 'Deposit', feature_id: 'merchant_deposit_generator', feature_list: [], feature_name: 'Deposit', icon: '', parent_feature_id: 'merchant_deposit', perm: 1, route: 'Merchant Deposit', title: 'Deposit',
          }, {
            created_at_ms: '0', description: 'Withdraw', feature_id: 'merchant_withdraw_generator', feature_list: [], feature_name: 'Withdraw', icon: '', parent_feature_id: 'merchant_withdraw', perm: 1, route: 'Merchant Withdraw', title: 'Withdraw',
          }],
        }]
        localStorage.setItem('navMenuItems', JSON.stringify(navMenuItems))
        this.$store.dispatch('wallet/changeNavMenuItems', navMenuItems)
        this.$router.replace('/merchant-list')
        return
      }

      this.isAuthCode = true
      this.$nextTick(() => {
        this.$refs.loginForm.validate().then(async success => {
          if (success) {
            const params = {
              platform: 3,
              user_name: this.userEmail,
              auth_code: this.authenticationCode,
              password: this.password,
            }
            this.$showLoading()
            const response = await this.$api.login(params).catch(err => {
              this.$hideLoading()
              this.showToast('warning', err.message)
            })

            // const params = {
            //   account: this.userEmail,
            //   password: this.password,
            // }
            // this.$showLoading()
            // const response = await this.$api.login2(params)
            if (response.data.code === 200) {
              const { data } = response.data
              const { token } = data
              const role = 'manage'
              // pageName 页面权限
              // const pageName = ['Deposit', 'Withdraw', 'Transfer', 'User Center', 'Download', 'Deposit Channel', 'Withdraw Channel', 'Service Fee']
              const pageName = []
              let ability
              if (role === 'manage') {
                ability = [{ action: 'manage', subject: 'all' }]
              } else {
                ability = [{ action: 'read', subject: 'Auth' }]
                if (pageName.length === 0) {
                  // 没有权限
                  this.$router.push({ name: 'misc-not-authorized' })
                  return
                }
              }
              localStorage.setItem('accessToken', token)
              localStorage.setItem('refreshToken', token)
              const userData = Object.assign(data, {
                ability,
                username: this.userEmail,
                role,
                avatar:
                    '/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/13-small.d796bffd.png',
                // 返回的有权限的页面名称数组
                pageName,
              })
              const navMenuItems = await updateNavMenuItems()
              localStorage.setItem('navMenuItems', JSON.stringify(navMenuItems))

              const [defaultRouteName] = getDefaultName()
              this.$store.commit('wallet/UPDATE_DEFAULTROUTENAME', defaultRouteName)
              localStorage.setItem('defaultRouteName', defaultRouteName)

              // 获取当前的左侧目录
              // this.$store.commit('wallet/UPDATE_NAVMENUITEMS', navMenuItems)
              setTimeout(() => {
                this.$store.dispatch('wallet/changeNavMenuItems', navMenuItems)
              }, 500)
              this.$ability.update(userData.ability)
              localStorage.setItem('userData', JSON.stringify(data))
              // this.$router.replace("/");
              const that = this
              this.$hideLoading()
              setTimeout(() => {
                this.$router.push({ name: store.state.wallet.defaultRouteName || localStorage.getItem('defaultRouteName') }).then(() => {
                  that.$toast(
                    {
                      component: ToastificationContent,
                      position: 'top-center',
                      props: {
                        title: `Welcome ${userData.username}`,
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: 'You have successfully logged in. ',
                      // Now you can start to explore!
                      },
                    },
                    {
                      position: 'top-center',
                    },
                  )
                })
              }, 10)
            } else {
              this.$hideLoading()
              this.showToast('warning', response.data.message)
            }

          // useJwt.login({
          //   account: this.userEmail,
          //   password: this.password,
          // }).then(response => {
          //   this.$hideLoading()
          //   if (response.data.code == 200) {
          //     const { data } = response.data
          //     const role = 'manage'
          //     // pageName 页面权限
          //     // const pageName = ['Deposit', 'Withdraw', 'Transfer', 'User Center', 'Download', 'Deposit Channel', 'Withdraw Channel', 'Service Fee']
          //     const pageName = []
          //     let ability
          //     if (role == 'manage') {
          //       ability = [{ action: 'manage', subject: 'all' }]
          //     } else {
          //       ability = [{ action: 'read', subject: 'Auth' }]
          //       if (pageName.length == 0) {
          //         // 没有权限
          //         this.$router.push({ name: 'misc-not-authorized' })
          //         return
          //       }
          //     }
          //     useJwt.setToken(response.data.data.token)
          //     useJwt.setRefreshToken(response.data.data.token)
          //     const userData = Object.assign(data, {
          //       ability,
          //       username: this.userEmail,
          //       role,
          //       avatar:
          //           '/demo/vuexy-vuejs-admin-dashboard-template/demo-1/img/13-small.d796bffd.png',
          //       // 返回的有权限的页面名称数组
          //       pageName,
          //     })
          //     this.$store.dispatch({
          //       type: 'wallet/changeNavMenuItems',
          //       pageName: userData.pageName,
          //     })
          //     this.$ability.update(userData.ability)
          //     localStorage.setItem('userData', JSON.stringify(data))
          //     // this.$router.replace("/");
          //     const that = this
          //     this.$router
          //       .replace(getHomeRouteForLoggedInUser(userData.role))
          //       .then(() => {
          //         that.$toast(
          //           {
          //             component: ToastificationContent,
          //             position: 'top-center',
          //             props: {
          //               title: `Welcome ${userData.username}`,
          //               icon: 'CoffeeIcon',
          //               variant: 'success',
          //               text: 'You have successfully logged in. ',
          //               // Now you can start to explore!
          //             },
          //           },
          //           {
          //             position: 'top-center',
          //           },
          //         )
          //       })
          //   } else {
          //     this.showToast('warning', response.data.message)
          //   }
          // }).catch(error => {
          //   this.$hideLoading()
          //   this.showToast('warning', error.message)
          // })
          }
        })
      })
    },
    hideModal() {
      this.$refs.googleAuth.hide()
    },
    showToast(variant = null, message = '') {
      this.$bvToast.toast(`${message}`, {
        title: `${variant || 'Error'}`,
        variant,
        solid: true,
        toaster: 'b-toaster-top-center',
      })
    },
  },
}
</script>

<style lang="scss">
.auth-wrapper {
  background: url(../../../assets/images/login/bg.jpg) no-repeat;
  background-size: cover;
}
.auth-inner {
  width: 34rem;
  margin-left: 50% !important;
  transform: translate(-50%, 0);
}
.auth-login {
  margin: 10rem auto;
}
.auth-title {
  font-weight: bold;
}
.auth-login-form {
  padding: 0 2rem;
}
.login-modal-p {
  margin: 10px 0 0 10px;
  font-weight: 500;
}
.login-qrcode {
  border: 2px solid #897ef2;
  margin: 0 auto;
  width: 220px;
  height: 220px;
  margin-top: 20px;
  img {
    width: 100%;
  }
}
.login-modal-okey {
  width: 150px;
  margin: auto;
}
</style>
